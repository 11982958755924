/**
 * @author gudiskis PrivaSphere confidential, (c) 2023 - 2024 all rights reserved
 */
import constVar from "../../../angular/src/assets/i18n/fr.json";
export const frTranslation = {
    ts_Delete: "Supprimer",
    ts_CheckAttaBeforeDispatch: "Vérifie la pièce jointe avant l&#39;envoi",
    ts_TooBig: "Trop grand",
    ts_attachment: "pièce jointe",
    ts_available: "disponible",
    ts_cumulated: "cumulated",
    ts_fileNameExtension: "filename-extension",
    ts_instead: "au lieu de",
    ts_moreInfo: "Plus d'infos",
    ts_in_aNewWindow: "dans une nouvelle fenêtre de navigateur",
    ts_MoreThanOneNumberGroupPresent: "Plus d'un groupe de nombres est présent.",
    ts_NoNumberPresent: "Aucun nombre n'est présent.",
    ts_TooManyDecimalDelimiters: "Trop de délimiteurs décimaux.",
    ts_TooManyMinusSigns: "Trop de signes négatifs.",
    ts_LeadingZerosRemoved: "Zéros initiaux supprimés.",
    ts_WhitespaceRemoved: "Espaces supprimés.",
    ts_CommasReplacedWithDots: "Virgules remplacées par des points.",
    ts_MultipleMinusSignsReducedToOne: "Plusieurs signes moins réduits à un.",
    ts_MultipleDecimalDelimitersReducedToOne: "Plusieurs délimiteurs décimaux réduits à un.",
    ts_NonNumberRemoved: "Caractères non numériques supprimés (sauf - et . ).",
    ts_NumberRounded: "Le nombre a été arrondi.",
    ts_FailedRoundingTheNumber: "Échec de l'arrondi du nombre.",
    ts_MinusSignNotAllowed: "Le signe moins n'est pas autorisé et a été supprimé.",
    ts_QuoteRemoved: "Guillemets ('\") supprimés.",
    ts_Input: "Entrée",
    ts_IsInvalid: "est invalide",
    ts_showFiles: "Afficher les fichiers",
    ts_hideFiles: "Masquer les fichiers",
    ts_deleteAllFiles: "Supprimer tous les fichiers",
    sd_noFilesSelected: constVar["sd_noFilesSelected"],
    ts_filesSelected: "fichiers à télécharger",
    ts_onlyOneFileAllowed: "Seul un fichier est autorisé pour cette entrée",
    sd_From: constVar["sd_From"],
    sd_to: constVar["sd_to"],
    ts_correctGroups: "Entrées de groupe correctes multiples détectées, choisissez-en une : ",
    ts_incorrectCharacter: "Caractère incorrect détecté : ",
    ts_inputTooLong: "L'entrée contient trop de caractères. Maximum autorisé :",
    ts_currently: "Actuellement : ",
    ts_inputTooShort: "L'entrée contient trop peu de caractères. Minimum requis :",
    ts_outOfRangeDate: "La date entrée est hors de la plage.",
    ts_outOfRangeTooEarly: "est trop tard lorsque le maximum autorisé est",
    ts_outOfRangeTooLate: "est trop tôt lorsque le minimum autorisé est",
    ts_noValidDateFound: "La date entrée n'est pas valide.",
    ts_dateChangedTo: "n'est pas une date valide et a été changée en",
    ts_dateChangedFrom: "La date entrée a été changée de",
    ts_unsupportedDateFormat: "Format de date non pris en charge",
    ts_parameter: "Paramètre",
    sd_Month: constVar["sd_Month"],
    sd_Year: constVar["sd_Year"],
    sd_day: constVar["sd_day"],
    ts_pleaseChange: "Veuillez changer",
    ts_toMatchTheFormat: "pour correspondre au format correct",
    ts_whitespacesRemoved: "espaces blancs supprimés",
    ts_whitespaceRemoved: "espace blanc supprimé",
    ts_wrongSeparators: "sont des séparateurs incorrects, le format correct est",
    ts_dateFormatChangedTo: "Format de date changé en",
    sd_of: constVar["sd_of"],
    sd_moreInfo: `${constVar["sd_moreIn"]} (${constVar["sd_in_aNewWindow"]})`,
    sd_orLess: constVar["sd_orLess"],
    sd_interoperable: constVar["sd_interoperable"],
    sd_registeredTitlePrefix: constVar["sd_registeredTitlePrefix"],
    sd_PermittedExtensions: constVar["sd_PermittedExtensions"],
    ts_leadtrailRemoved: "supprimer les caractères non-ascii et spéciaux de début et de fin",
    ts_emailmusthaveat: "An " + constVar["sd_Email_ad"] + " doit avoir un \"\@\" !",
    ts_emailmusthavedomain: "An " + constVar["sd_Email_ad"] + " doit se terminer par \"domain.topLevelDomain\" par exemple \"ti.ch\" !",
    ts_wrongEmailSeparators: constVar["sd_emailEntryTooltip"] + " - " + constVar["sd_not"] + " \";\" (" + constVar["sd_semicolon"] + ") !",
    ts_emailmustonlyone: "Un seul " + constVar["sd_Email_ad"] + " est autorisé !",
    ts_extraDotRemovedfromAdjacenttoAt: "le point supplémentaire est supprimé pour celui adjacent à \"\@\" !",
};

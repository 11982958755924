/**
 * @author gudiskis, ancona PrivaSphere confidential, (c) 2024 - 2024 all rights reserved
 *
 * https://git.privasphere.com/privasphere/privalope/-/issues/640
 *
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class RequestUtils {
    static completeReqURL(delim) {
        let dotD = '.d;';
        let jsessionIdString = this.getParam('jsessionid')
            ? 'jsessionid=' + this.getParam('jsessionid')
            : '';
        let loginString = this.getParam('login')
            ? delim + 'login=' + this.getParam('login')
            : '';
        let n0wString = this.getParam('n0w') ? '&n0w=' + this.getParam('n0w') : '';
        let res = dotD + jsessionIdString + loginString + n0wString;
        return res;
    }
    static getParam(name) {
        var rx = new RegExp('[;?&]' + name + '=([^&?/]+).*$');
        var returnVal = window.location.href.match(rx);
        return returnVal === null ? '' : returnVal[1];
    }
    static makeRequest(url, method, data) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const xhr = new XMLHttpRequest();
                xhr.open(method, url, true);
                xhr.setRequestHeader('Content-Type', 'application/json');
                const response = yield new Promise((resolve, reject) => {
                    xhr.onreadystatechange = function () {
                        if (xhr.readyState === 4) {
                            if (xhr.status === 200) {
                                resolve(xhr.responseText);
                            }
                            else {
                                reject(xhr.responseText);
                            }
                        }
                    };
                    xhr.send(JSON.stringify(data));
                });
                return response;
            }
            catch (error) {
                throw new Error(error);
            }
        });
    }
}
